import selectorService from '@/business/selectorService';
import AutocompleteComponent from '@/components/commons/autocomplete/AutocompleteComponent.vue';
import SelectorComponent from '@/components/commons/selector/SelectorComponent.vue';
import MapToolsComponent from '@/components/map/mapTools/MapToolsComponent.vue';
import leaflet from 'leaflet';
import 'leaflet/dist/leaflet.css';
import moment from 'moment';
import filterService from '../../business/filter.service';
import thingService from '../../business/thingService';
import { goFullscreenById } from '../../tools/functions';
import { DEFAULT_REFRESH_DATA, DEFAULT_STOREROOM_RADIUS, FILTERS } from './constants';
import harvestFrontMapService from './harvestFrontMap.service';

export default {
    name: 'MapBaseComponent',
    components: {
        SelectorComponent,
        AutocompleteComponent,
        MapToolsComponent
    },
    props: {},
    data() {
        return {
            mapId: 'mapBaseComponent',
            mapInstance: null,
            loadingSpinner: false,
            markersMap: {},
            updateInterval: null,
            drawer: false,
            dialogCluster: false,
            thingItems: [],
            thingSelected: null,
            markersGroup: null,
            configBase: {
                idElement: 'mapBaseComponent',
                centerPosition: [-24.475390108274823, -65.03700482239184],
                zoom: 8,
                preferCanvas: false,
                zoomControl: true,
                renderer: leaflet.canvas()
            },
            selectorModel: selectorService.getSelectorModel(false),
            thingsFilter: FILTERS.THINGS,
            sectorFilter: FILTERS.SECTOR,
            activityFilter: FILTERS.ACTIVITIES,
            thingTypeFilter: FILTERS.TYPES,
            stateFilter: FILTERS.STATES,
            categoryFilter: FILTERS.CATEGORIES,
            harvestFrontFilter: FILTERS.HARVEST_FRONT,
            userThings: null,
            harvestFronts: null,
            harvestFrontsMap: new Map(),
            lastUpdateTimestamp: null,
            defaultRadius: DEFAULT_STOREROOM_RADIUS,
            radiusFilter: {
                show: false, label: "mapHistory.selector.radius.label", id: "storeRoomRadius", value: DEFAULT_STOREROOM_RADIUS, title: "mapHistory.selector.radius.title"
            },
            showZones: false,
            zonesMakerMap: new Map(),
            pointsOfInterestMap: new Map(),
            filters: [],
            markerClusterSize: 40,
            showLabels: false,
            showHidePointsOfInterest: false,
            zoomClusterMarkers: false,
            fullscreenIcon: 'fullscreen',
            isFullScreen: false,
            thingsMapped: {}
        }
    },
    beforeDestroy() {
        clearInterval(this.updateInterval);
    },
    async mounted() {
        this.loadingSpinner = true;
        try {
            await harvestFrontMapService.getUpdatedIcons();
            this.harvestFronts = await filterService.getAllHarvestFront();
            this.createHarvestFrontMap();
            this.mapInstance = harvestFrontMapService.createMap(this.configBase);
            await this.initFilterSelector();
            this.userThings = await thingService.getAllUserThingsByEnterprise();
            this.thingsMapped = await thingService.getAllUserThingMapped();
            this.thingItems = this.userThings.map((thingSelected) => { return { name: thingSelected.name, id: thingSelected._id } });
            const harvestFrontsStatus = await harvestFrontMapService.getHarvestFrontStatus(this.harvestFronts.map(harvestFront => harvestFront.id));
            const tracks = [];
            harvestFrontMapService.updateLastTracks(harvestFrontsStatus, 1, tracks, this.thingsMapped, this.harvestFrontsMap);
            const { markersMap, markersGroup } = await harvestFrontMapService.createMarkerByTracks(this.mapInstance, tracks, this.defaultRadius, this.showLabels);
            this.markersMap = markersMap;
            this.markersGroup = markersGroup;
            this.lastUpdateTimestamp = moment().utc().unix();
            this.startInterval();
        } finally {
            this.loadingSpinner = false;
        }
    },
    computed: {},
    methods: {
        createHarvestFrontMap() {
            if (this.harvestFronts && this.harvestFronts.length > 0) {
                for (const harvestFront of this.harvestFronts) {
                    this.harvestFrontsMap.set(harvestFront.id, harvestFront);
                }
            }
        },
        async startInterval() {
            this.updateInterval = setInterval(async () => {
                const tracks = [];
                const harvestFrontsStatus = await harvestFrontMapService.getHarvestFrontStatus(this.harvestFronts.map(harvestFront => harvestFront.id));
                harvestFrontMapService.updateLastTracks(harvestFrontsStatus, this.lastUpdateTimestamp - 1, tracks, this.thingsMapped, this.harvestFrontsMap);
                this.lastUpdateTimestamp = moment().utc().unix();
                harvestFrontMapService.filterTrucks(this.markersMap, this.markersGroup);
                harvestFrontMapService.updateMarkersByTracks(tracks, this.markersMap, this.markersGroup, this.mapInstance, this.radiusFilter.value, this.filters, this.showLabels);
            }, DEFAULT_REFRESH_DATA);
        },
        async applyFilters() {
            this.loadingSpinner = true;
            clearInterval(this.updateInterval);
            try {
                const filters = [];
                const poinZoneFilters = [];
                const harvestFrontMap = new Map();

                if (this.harvestFrontFilter.selectedData && this.harvestFrontFilter.selectedData.length > 0) {
                    filterService.getSelectorMapById(this.harvestFrontFilter.selectedData, harvestFrontMap);
                    filters.push({ field: "harvestFrontId", conditions: harvestFrontMap });
                }
                this.filters = filters;
                harvestFrontMapService.filterMarkersByTrackData(filters, this.mapInstance, this.markersGroup, this.markersMap);
                harvestFrontMapService.filterPointOfInterestAndZones(this.mapInstance, Array.from(this.pointsOfInterestMap.values()), Array.from(this.zonesMakerMap.values()), poinZoneFilters);
                harvestFrontMapService.updateCircleRadius(Array.from(this.markersMap.values()), this.radiusFilter.value);
            } finally {
                this.startInterval();
                this.loadingSpinner = false;
            }
        },
        async initFilterSelector() {
            this.selectorModel.showTimeFormatSelector = false;
            this.selectorModel.showDateAndTimeSelector = false;
            this.selectorModel.hideAutoReport = true;
            this.selectorModel.generateButtonLabel = "map.apply";
            this.selectorModel.disableGetReportButton = false;

            this.selectorModel.numericInputs.cleanAndUpdate([this.radiusFilter]);
            const [
                harvestFronts
            ] = await Promise.all([
                filterService.getAllHarvestFront()
            ])
            this.harvestFrontFilter.data.cleanAndUpdate(harvestFronts);

            this.selectorModel.filters.push(this.harvestFrontFilter);
        },
        centerMap(event) {
            if (event && event.id) {
                const marker = this.markersMap.get(event.id);
                harvestFrontMapService.centerMapByMarker(marker, 20, this.mapInstance);
            }
        },
        async showHideZones() {
            this.loadingSpinner = true;
            try {
                this.showZones = !this.showZones;
                await harvestFrontMapService.showHideZonesByEnteprise(this.showZones, this.mapInstance, this.zonesMakerMap);
            } finally {
                this.loadingSpinner = false;
            }
        },
        showEditClusterDialog() {
            this.dialogCluster = true;
        },
        saveEditCluster() {
            this.loadingSpinner = true;
            clearInterval(this.updateInterval);
            try {
                this.markersGroup = harvestFrontMapService.updateMaxClusterRadius(this.mapInstance, Array.from(this.markersMap.values()), this.markersGroup, this.markerClusterSize);
            } finally {
                this.loadingSpinner = false;
                this.dialogCluster = false;
                this.startInterval();
            }
        },
        async showTooltipAction() {
            this.loadingSpinner = true;
            clearInterval(this.updateInterval);
            try {
                this.showLabels = !this.showLabels;
                await harvestFrontMapService.updateIconsOfMarkers(Array.from(this.markersMap.values()), this.showLabels);
            } finally {
                this.loadingSpinner = false;
                this.startInterval();

            }
        },
        async showPointsOfInterest() {
            this.showHidePointsOfInterest = !this.showHidePointsOfInterest;
            await harvestFrontMapService.showHidePointOfInterest(this.mapInstance, this.showHidePointsOfInterest, this.pointsOfInterestMap);
        },
        fullScreen() {
            this.isFullScreen = !this.isFullScreen;
            this.fullscreenIcon = this.isFullScreen ? "fullscreen_exit" : "fullscreen";
            goFullscreenById(this.mapId + "element");
        }
    }
}
